<template>
  <div :style="{width: pageWidth + 'px', height: pageHeight + 'px', overflow: 'hidden'}">
    <!-- <dv-full-screen-container :key="dataKey"> -->
      <dv-loading v-if="loading">加载中</dv-loading>
      <div class="map-view">
        <div class="header">
          <div style="padding: 0 2vh; width: max-content;">
            <a-button
              type="primary"
              @click="showDrawer"
              v-if="!visible"
            >
              标站设定
            </a-button>
            <a-button
              type="primary"
              @click="showMapSettings"
              v-show="!visibleMap"
              style="margin-left: 0.5vh;"
            >
              地图设定
            </a-button>
          </div>
          <div class="title">
            <dv-decoration-11 style="width: 16vw; height: 6vh"> 裸土遥感监管服务系统 </dv-decoration-11>
          </div>
          <!-- <dv-decoration-5 style="width: 18vw; height: 6vh" /> -->
          <div class="extra">
            <span style="color: #fff; font-size: 2vh; width: max-content">遥感时间：</span>
            <a-select
              v-model="year"
              :options="[{value: 2023, label: '2023年度'}, {value: 2024, label: '2024年度'}]"
              style="width: 120px; padding-right: 5px"
              @change="handleYearDataChange"
            >
            </a-select>
            <a-select
              v-model="quarterData"
              :options="quarterDataList"
              style="width: 100px; padding-right: 5px"
              @change="handlequarterDataChange"
            >
            </a-select>
            <a-cascader v-model="remoteSensingDistrict" :placeholder="'请选择'" :options="remoteSensingDistrictList" change-on-select @change="handleChangeDate" style="width: 175px" :allowClear = "false"/>
            <a-button @click="handleClick">下载</a-button>
            <span style="color: #fff; font-size: 2vh; width: max-content;padding-left: 5px">整改完成：</span>
            <a-switch class="rectification-switch-class" :style="{background: rectificationCompletedSwitch ? '#13ce66' : '#ccc', padding: 0, margin: 0, width: '60px', height: '30px'}" 
              v-model="rectificationCompletedSwitch" @change="rectificationCompletedSwitchChange"/>
          </div>
        </div>
        <div class="container">
          <dv-border-box-12 :key="dataKey" class="map-container">
            <GaoDeMapView ref="gaoDeMapView" v-if="mapSetting === 'gaodemap'" @getGaoDeMap="getGaoDeMap">
            </GaoDeMapView>
            <map-view
              ref="mapview"
              @getMap="getMap"
              :satationDrawRangeVal="satationDrawRangeVal"
              :remoteLayerInfo="remoteLayerInfo"
              v-if="mapVisible === 'wgs84'"
            ></map-view>

            <transition name="map-container" appear>
              <div style="z-index: 555" class="panel-content" v-if="visible">
                <a-button type="primary" @click="showDrawer"> 关闭标站设定 </a-button>
                <a-popover placement="bottom" style="float: right;">
                  <template slot="content">
                    <div style="width: 500px;">
                      <h3>1.地理坐标系（Geographic coordinate system）</h3>
                      <p>地图使用的坐标为EPSG:4326 (WGS84)坐标系，是目前最流行的地理坐标系统，以参考椭球中心为原点，起始子午面和赤道面为基准面的地球坐标系。使用大地的经度纬度和高度来描述位置。</p>
                      <h3>2.投影坐标系（Projected coordinate systems）</h3>
                      <p>地理坐标系是三维的，我们要在地图或者屏幕上显示就需要转化为二维，这被称为投影（Map projection）。显而易见的是，从三维到二维的转化，必然会导致变形和失真，失真是不可避免的，但是不同投影下会有不同的失真，这让我们可以有得选择。</p>
                      <p>常用的投影有等矩矩形投影（Platte Carre）和墨卡托投影（Mercator），下图来自Mercator vs. well…not Mercator (Platte Carre)，生动地说明了这两种投影下的失真：</p>
                      <img src="../assets/diqiu.png"  height="300" >
                      <h3>3.标站范围绘制</h3>
                      <p>地图使用了等矩矩形投影（Platte Carre）的方式（右下角），绘制站点周边范围时，绘制出的方圆会被拉伸，真实效果展示为椭圆型。</p>
                    </div>
                  </template>
                  <template slot="title">
                    <span>标站绘制</span>
                  </template>
                  <a-button type="primary">标站绘制范围说明</a-button>
                </a-popover>
                <div id="map-container" style="width: 400px; height: 100%">
                  <dv-border-box-13 style="height: 100%; overflow: auto">
                    <div id="modalBox" class="modalBox">
                      数据配置：<a-select
                        :default-value="stationType[0]"
                        style="width: 200px"
                        @change="satationChane"
                        mode="multiple"
                      >
                        <a-select-option v-for="i of stationType" :key="i">
                          {{ i }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div class="circleChecked">
                      标站绘制范围设定：<a-select :default-value="5000" @change="satationDrawRangeChange">
                        <a-select-option
                          v-for="staDraw of satationDrawRange"
                          :key="staDraw.value"
                          :title="staDraw.label"
                        >
                          {{ staDraw.label }}
                        </a-select-option>
                      </a-select>
                      一键绘制/取消绘制
                      <a-switch @change="circleAllCheckedChange" v-model="allSwitch" />
                    </div>
                    <div class="map-region">
                      区县：<a-select
                        @change="selectedRegionChange"
                        mode="multiple"
                        allowClear
                        style="width: 320px; max-width: 320px"
                        :maxTagCount="2"
                        v-model="selectedRegion"
                      >
                        <a-select-option
                          v-for="region of regionTree"
                          :key="region.id + ':' + region.name"
                          :title="region.name"
                        >
                          {{ region.name }}
                        </a-select-option>
                      </a-select>
                      <div class="region">
                        <a-checkbox @change="onRegionChange" v-model="bdRegion" :disabled = "regionDisabled"> 百度行政区划 </a-checkbox>
                        <a-checkbox @change="onRegionChange" v-model="gdRegion" :disabled = "regionDisabled"> 高德行政区划 </a-checkbox>
                        <a-checkbox @change="onRegionChange" v-model="nfRegion" :disabled = "regionDisabled"> 诺方行政区划 </a-checkbox>
                      </div>
                    </div>
                    <div style="height: 100%; overflow: auto">
                      <vue-good-table
                        :columns="stationColumns"
                        :rows="showStations"
                        :pagination-options="paginationOptions2"
                        theme="nocturnal"
                        class="station-table"
                        :line-numbers="true"
                        style="padding:5px 15px 15px 15px; height: 100%; overflow: hidden; table-layout: fixed; overflow: auto"
                      >
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'switch'">
                            <a-switch v-model="props.row.switchFlg" @change="circleCheckedChange($event, props)" />
                          </span>
                          <span v-else-if="props.column.field == 'order'">
                            {{ props.$index }}
                          </span>
                          <span v-else-if="props.column.field == 'pm10'">
                            {{ props.row.pm10 ? props.row.pm10 : '--' }}
                          </span>
                        </template>
                        <div
                          slot="emptystate"
                          style="
                            font-size: 1.7vh;
                            text-align: center;
                            height: 4vh;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: none;
                          "
                        >
                          暂无数据
                        </div>
                      </vue-good-table>
                    </div>
                  </dv-border-box-13>
                </div>
              </div>
            </transition>
            <transition name="map-container" appear>
              <div style="z-index: 555" class="panel-content" v-if="visibleMap">
                <a-button type="primary" @click="showMapSettings"> 关闭地图设定 </a-button>
                <div id="map-container" style="width: 400px; height: 100%">
                  <dv-border-box-13 style="height: 100%; overflow: auto">
                    <div id="modalBox" class="modalBox" style="margin-bottom: 20px">
                      底图配置：<a-radio-group
                        default-value="original"
                        button-style="solid"
                        @change="mapSettingChange"
                        v-model="mapSetting"
                      >
                        <!-- <a-radio-button :value="'original'"> 原始 </a-radio-button> -->
                        <a-radio-button :value="'tiandimap'"> 天地图 </a-radio-button>
                        <a-radio-button :value="'baidumap'" disabled> 百度 </a-radio-button>
                        <a-radio-button :value="'gaodemap'"> 高德 </a-radio-button>
                      </a-radio-group>
                      <div style="margin-top: 10px">
                        是否显示地图底图
                        <a-switch @change="baseMapSwitchChange" v-model="baseMapSwitch" />
                      </div>
                      <div style="margin-top: 10px">
                        是否显示遥感影像
                        <a-switch @change="remoteSwitchChange" v-model="remoteSwitch" />
                      </div>
                      <div style="margin-top: 10px">
                        是否显示裸土区域
                        <a-switch @change="bareSoilSwitchChange" v-model="bareSoilSwitch" />
                      </div>
                      <div class="centered-container" v-if="this.$store.state.user && this.$store.state.user.info.userType === '00'">
                        是否显示第三方数据
                        <a-switch @change="onThirdSwitchChange" v-model="thirdSwitch" />
                        <a-select
                          @change="onThirdSelectChange"
                          v-model="thirdSelect"
                          mode="multiple"
                          :maxTagCount="1"
                          :maxTagTextLength="5"
                          class="thirdselectcss"
                        >
                        <a-select-option v-for="i of thirdSelectItems" :key="i">
                          {{ i }}
                        </a-select-option>
                      </a-select>
                      </div>
                      <!-- <div style="margin-top: 10px">
                        开始绘制裸土区域
                        <a-switch @change="startDraw" v-model="drawSwitch" disabled />
                      </div> -->
                    </div>
                  </dv-border-box-13>
                </div>
              </div>
            </transition>
          </dv-border-box-12>
          <div class="right-content">
            <dv-border-box-12 class="table-container">
              <a-table
                :columns="columns"
                :row-key="(record) => record.gid"
                :data-source="tableData"
                bordered
                class="right-table-header"
                size="middle"
                style="
                  height: 100%;
                  width: 100%;
                  overflow: hidden;
                  table-layout: fixed;
                  overflow: auto;
                  background-color: #324057;
                "
                :rowClassName="
                  () => {
                    return 'column-ant'
                  }
                "
                :customRow="onCellClick"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                  >
                    搜索
                  </a-button>
                  <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"> 重置 </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template slot="customRender" slot-scope="text, record, index, column">
                  <span v-if="searchText && searchedColumn === column.dataIndex">
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                      <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{
                        fragment
                      }}</mark>
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    {{ text }}
                  </template>
                </template>
              </a-table>
            </dv-border-box-12>
            <dv-border-box-12 class="pie-container" :key="dataKey">
              <pie-chart ref="pieChart" @onClick="handleFilterBareareaT"></pie-chart>
              <div class="bar">
                <button
                  @click="handleChangePie('count')"
                  :style="{
                    backgroundColor: pieType === 'count' ? '#69a7b0' : '#a2c4c9',
                    cursor: 'pointer',
                  }"
                >
                  数量
                </button>
                <button
                  @click="handleChangePie('area')"
                  :style="{
                    backgroundColor: pieType === 'area' ? '#69a7b0' : '#a2c4c9',
                    cursor: 'pointer',
                  }"
                >
                  面积
                </button>
              </div>
            </dv-border-box-12>
          </div>
        </div>
      </div>
      <map-popup ref="popup" :currentId="currentId" :mapSetting="mapSetting" @closePopup="closePopup"></map-popup>
    <!-- </dv-full-screen-container> -->
  </div>
</template>

<script>
/* eslint-disable */
import dayjs from 'dayjs'
import {
  getDistrictBareSoil,
  getRemoteSenseTimeList,
  download,
  getStationDataList,
  getRegionTreeList,
  getRegionBoundary,
  getBdRegionBoundary,
  getGdRegionBoundary,
  getThirdSelectItems,
  getThirdData,
} from '@/api'
import { configBoard, configChart, colorDict, stationColumns } from '@/options'
import MapView from '@/components/MapView.vue'
import MapPopup from '@/components/MapPopup.vue'
import PieChart from '@/components/PieChart.vue'
import GaoDeMapView from '@/components/GaoDeMapView.vue'
import Vue, { nextTick } from 'vue'
import StationPage from '@/components/Station.vue'
import { districtOrder } from '@/utils/utils'
export default {
  name: 'HomePage',
  components: {
    MapView,
    MapPopup,
    PieChart,
    GaoDeMapView,
  },
  data() {
    return {
      mapSetting: '',
      stationColumns,
      loading: false,
      map: null,
      configBoard,
      configChart,
      date: '',
      popup: null,
      selectedStatus: 'all',
      areasData: [],
      storeData: [],
      tableData: [],
      selectedRegion: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      currentId: 0,
      currentIdCopy: 0,
      pieType: 'count',
      dur: 6,
      remoteSensingDistrict: null,
      remoteSensingDistrictList: [],
      quarterDataList: [
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 },
      ],
      quarterData: null,
      tags: [],
      statusCount: {
        all: 0,
        patroled: 0,
        notPatrolled: 0,
      },
      paginationOptions: {
        enabled: true,
        nextLabel: '下一页',
        prevLabel: '上一页',
        perPageDropdownEnabled: false,
        infoFn: (params) => {
          return `共 ${params.totalRecords} 条`
        },
        perPage: 10,
      },
      paginationOptions2: {
        enabled: true,
        nextLabel: '下一页',
        prevLabel: '上一页',
        perPageDropdownEnabled: false,
        infoFn: (params) => {
          return `共 ${params.totalRecords} 条`
        },
      },
      bareareaTDict: {},
      visible: false,
      visibleMap: false,
      stationType: ['国', '省', '街镇'],
      showStations: [],
      circleChecked: false,
      satationDrawRange: [
        { label: '1km', value: 1000 },
        { label: '1.5km', value: 1500 },
        { label: '2km', value: 2000 },
        { label: '3km', value: 3000 },
        { label: '4km', value: 4000 },
        { label: '5km', value: 5000 },
      ],
      satationDrawRangeVal: 5000,
      regionTree: [],
      allSwitch: false,
      baseMapSwitch: false,
      remoteSwitch: true,
      remoteLayerInfo: null,
      mapVisible: '',
      bareSoilSwitch: true,
      remoteSensingImages: [],
      drawSwitch: false,
      popupShowFlg: false,
      bdRegion: false,
      gdRegion: false,
      nfRegion: true,
      regionDisabled: false,
      columns: [
        {
          title: '裸土编号',
          dataIndex: 'gid',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.gid.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: '所属街道',
          dataIndex: 'town',
        },
        {
          title: '裸土面积(平方米)',
          dataIndex: 'area',
          type: 'number',
        },
      ],
      pageWidth: document.body.clientWidth,
      pageHeight: document.body.clientHeight,
      dataKey: Math.random(),
      year: 2024,
      thirdSelectItems:[],
      thirdSwitch:false,
      thirdSelect:[],
      rectificationCompletedSwitch: false,
    }
  },
  created() {
    this.date = dayjs().format('YYYY-MM-DD')
    this.tags = Object.keys(colorDict).map((key) => ({
      label: key,
      value: colorDict[key],
    }))
  },
  mounted() {
    console.log("🚀 ~ mounted ~ this.$store.state.info:", this.$store.state.user.info.userType)
    console.log("🚀 ~ mounted ~ this.$store.state.info:", this.$store.state)
    window.addEventListener("resize", () => {
      this.pageWidth = document.body.clientWidth
      this.pageHeight = document.body.clientHeight
      this.dataKey = Math.random()
      this.initOriginalMap()
      if(this.thirdSwitch){
        this.getThirdData(this.thirdSelect);
      }
    })
    this.initOriginalMap()
  },
  methods: {
    rectificationCompletedSwitchChange() {
      this.getAreasDataList()
    },
    async initOriginalMap() {
      // 获取遥感影像列表，用于右上角选择
      await this.getRemoteSenseTimeList()
      // 获取国控站点名称
      await this.getStationDataList()
      // 获取区县列表
      this.setRegionTree()
      return getThirdSelectItems().then(res => {
          const {code, data} = res
          if (code === 200) {
            const thirdSelectItems = []
            data.forEach(element => {
              thirdSelectItems.push(element)
            });
            this.thirdSelectItems = thirdSelectItems
            this.thirdSelect = [this.thirdSelectItems[0]]
          }
        })
    },
    // 获取遥感时间列表
    getRemoteSenseTimeList() {
      return getRemoteSenseTimeList().then((res) => {
        const { code, data } = res
        if (code === 200) {
          // 存储所有数据
          this.remoteSensingImages = data
          // 获取最新一条数据的季度，筛选最新季度的数据
          this.remoteSensingDistrictList = this.remoteSensingImages
            .filter((x) => x.quarter === this.remoteSensingImages[0].quarter)
            .sort((a, b) => Number(a.orderId) - Number(b.orderId))
          // 设定最新季度
          this.quarterData = parseInt(String(this.remoteSensingImages[0].quarter).slice(-1))
          // // 设定选择的展示区县
          this.remoteSensingDistrict = [this.remoteSensingDistrictList[0].value]
          // 将最新区县提供给mapview
          this.remoteLayerInfo = this.remoteSensingDistrictList[0]
          // 设定展示地图 wgs84为前两个
          this.mapVisible = 'wgs84'
          // 获取裸土区域列表数据
          this.getAreasDataList()
        }
      })
    },
    // 获取地图实例
    getMap(map) {
      this.map = map
      this.container = document.getElementById('popup')
      this.closer = document.getElementById('popup-closer')
      this.addPopup()
    },
    getGaoDeMap(map) {
      this.map = map
    },
    onCellClick(target, index) {
      let _this = this
      return {
        on: {
          click() {
            if (_this.mapSetting === 'gaodemap') {
              _this.currentId = target.gid
              _this.currentIdCopy = target.gid
              _this.$refs.popup.showPop(_this.currentId, _this.quarterData, _this.year)
              _this.popupShowFlg = true
              var infoWindow = new AMap.InfoWindow({
                content: _this.$refs.popup.$el, //传入 dom 对象，或者 html 字符串
              })

              var gps = [target.lng, target.lat]
              AMap.convertFrom(gps, 'gps', function (status, result) {
                if (result.info === 'ok') {
                  var lnglats = result.locations[0] // Array.<LngLat>
                  // 打开信息窗体
                  infoWindow.open(_this.map, [lnglats.lng, lnglats.lat])
                }
              })
            } else {
              _this.currentId = target.gid
              _this.currentIdCopy = target.gid
              if (target) {
                _this.$refs.popup.showPop(_this.currentId, _this.quarterData, _this.year)
                _this.popupShowFlg = true
                _this.popup.setPosition([target.lng, target.lat])
              } else {
                _this.recoverMap()
                if (target) {
                  _this.$refs.popup.showPop(_this.currentId, _this.quarterData, _this.year)
                  _this.popupShowFlg = true
                  _this.popup.setPosition([target.lng, target.lat])
                }
              }
            }
          },
        },
      }
    },
    recoverMap() {
      this.areasData = this.handleFilterData()
      this.popup.setPosition(undefined)
      this.$refs.mapview.drawPolygons(this.areasData)
      this.$refs.mapview.addFeatures(this.areasData)
    },
    // 添加弹窗
    addPopup() {
      // 创建Overlay
      this.popup = new ol.Overlay({
        element: this.$refs.popup.$el,
        autoPan: true,
        zIndex: 11,
      })
      this.map.addOverlay(this.popup)

      this.map.on('singleclick', this.singleclick)

      this.map.on('pointermove', this.pointermove)
    },

    singleclick(e) {
      // 判断是否点击在点上
      let feature = this.map.forEachFeatureAtPixel(e.pixel, (feature) => feature)
      if (feature) {
        const properties = feature.getProperties()
        if (properties.type && (properties.type !== 'areas') && (properties.valueType !== 'thirdPoint')) {
          this.popup.setPosition(undefined)
          return
        }

        if (properties.type === 'areas') {
          this.$refs.popup.showPop(properties.gid, this.quarterData, this.year)
          this.currentIdCopy = properties.gid
          this.popupShowFlg = true
          // 设置弹窗位置
          this.popup.setPosition([properties.lng, properties.lat])
        }else if (properties.valueType === 'thirdPoint') {
          console.log("🚀 ~ singleclick ~ properties.type:", properties.type)
          this.$refs.popup.showThirdPop(properties.thirdId, properties.thirdTitle)
          this.popupShowFlg = true
          // 设置弹窗位置
          this.popup.setPosition([properties.thirdLng, properties.thirdLat])
        }
      }else{
        this.popup.setPosition(undefined)
      }
    },
    pointermove(e) {
      if (this.map.hasFeatureAtPixel(e.pixel)) {
        let feature = this.map.forEachFeatureAtPixel(e.pixel, (feature) => feature)
        const properties = feature.getProperties()
        if (feature && properties.type === 'areas' || properties.valueType === 'thirdPoint') {
          this.map.getTargetElement().style.cursor = 'pointer'
        } else {
          this.map.getTargetElement().style.cursor = ''
        }
      } else {
        this.map.getTargetElement().style.cursor = ''
      }
    },
    doUpdate() {
      this.$refs['scrollBoard'].updateRows(rows, index)
    },
    // 点击顶部分类过滤数据
    handlePieClick(type) {
      this.selectedStatus = type
      this.areasData = this.handleFilterData()
      this.handleData()
      this.popup.setPosition(undefined)
      this.$refs.mapview.drawPolygons(this.areasData)
      this.$refs.mapview.addFeatures(this.areasData)
    },
    handleFilterData() {
      let data = [...this.storeData]
      if (this.selectedStatus === 'patroled') {
        data = data.filter((item) => item.status === '已巡查')
      } else if (this.selectedStatus === 'notPatrolled') {
        data = data.filter((item) => !item.status || item.status === '未巡查')
      }
      return data
    },
    // 获取裸土区域列表数据
    getAreasDataList() {
      let district = this.remoteSensingDistrictList.find((x) => x.value === this.remoteSensingDistrict[0]).label
      let town = this.remoteSensingDistrict[1] ?? '';
      const param = {
        year: this.year,
        quarter: this.quarterData,
        district: district,
        town: town,
        rectificationCompletedSwitch: this.rectificationCompletedSwitch
      };
      return getDistrictBareSoil(param)
        .then((res) => {
          const { code, data } = res
          if (code === 200) {
            this.storeData = data
            const statusCount = {}
            statusCount.all = data.length
            statusCount.patroled = data.filter((item) => item.status === '已巡查').length
            statusCount.notPatrolled = data.filter((item) => !item.status || item.status === '未巡查').length
            this.statusCount = statusCount
            this.areasData = this.handleFilterData()
            this.handleData()
            this.$refs.mapview.drawPolygons(this.areasData)
            this.$refs.mapview.addFeatures(this.areasData)
          }
        })
        .catch((err) => {
          console.log('🚀 ~ file: HomePage.vue:196 ~ getAreasData ~ err:', err)
        })
    },
    handleData() {
      const areasData = [...this.areasData]
      // 表格数据
      this.tableData = areasData
        .sort((a, b) => Number(b.area) - Number(a.area))
        .map((item, i) => ({
          ...item,
          area: Math.round(item.area),
          order: i + 1,
        }))
      // 圆形图数据
      this.areaDict = {}
      this.countDict = {}
      if (areasData.length) {
        for (let item of areasData) {
          if (this.countDict[item.bareareaT]) {
            this.countDict[item.bareareaT]++
          } else {
            this.countDict[item.bareareaT] = 1
          }
          if (item.area) {
            if (this.areaDict[item.bareareaT]) {
              this.areaDict[item.bareareaT] = this.areaDict[item.bareareaT] + item.area
            } else {
              this.areaDict[item.bareareaT] = item.area
            }
          }
        }
      }
      this.handleChangePie(this.pieType)
    },
    handleChangePie(type = 'count') {
      this.pieType = type
      let chartData = Object.keys(this.countDict).map((key) => ({
        name: key,
        value: this.countDict[key],
        itemStyle: { color: colorDict[key] },
      }))
      if (type === 'area') {
        chartData = Object.keys(this.areaDict).map((key) => ({
          name: key,
          value: Math.round(this.areaDict[key]),
          itemStyle: { color: colorDict[key] },
        }))
      }
      this.chartData = chartData
      this.$refs.pieChart.updateChart(this.chartData)
    },
    handleYearDataChange(year) {
      // 季度
      let quarter = Number(year + '0' + this.quarterData)

      // 获取选择季度遥感拍摄地区
      this.remoteSensingDistrictList = this.remoteSensingImages
        .filter((x) => x.quarter === quarter)
        .sort((a, b) => Number(a.orderId) - Number(b.orderId))
      this.getAreasDataList()
    },
    handleChangeDate(data) {
      this.remoteLayerInfo = this.remoteSensingDistrictList.find((x) => x.value === data[0])
      // 获取裸土信息
      this.getAreasDataList()
      if(this.thirdSwitch){
        console.log("🚀 ~ handleChangeDate ~ this.thirdSelect:", this.thirdSelect)
        this.getThirdData(this.thirdSelect);
      }
      // 重新设置底图
      // this.$refs.mapview.changeRemote()
    },
    rowStyleClassFn(row) {
      return row.originalIndex % 2 === 0 ? 'column-light' : 'column-deep'
    },
    // 过滤类型
    handleFilterBareareaT(type) {
      this.areasData = this.storeData.filter((item) => item.bareareaT === type)
      this.popup.setPosition(undefined)
      this.$refs.mapview.drawPolygons(this.areasData)
      this.$refs.mapview.addFeatures(this.areasData)
    },
    handleClick() {
      let district = this.remoteSensingDistrictList.find((x) => x.value === this.remoteSensingDistrict[0]).label
      let param = { year: this.year, quarter: this.quarterData, district: district, rectificationCompletedSwitch: this.rectificationCompletedSwitch }
      download(param).then((res) => {
        let filename = '裸土详细信息.xlsx'
        try {
          filename = decodeURI(res.headers['content-disposition'].split(';')[1].split('filename=')[1])
        } catch (err) {
          console.log(err)
        }
        // 创建blob对象，解析流数据
        const blob = new Blob([res.data], {
          // 设置返回的文件类型
          // type: 'application/pdf;charset=UTF-8' 表示下载文档为pdf，如果是word则设置为msword，excel为excel
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 这里就是创建一个a标签，等下用来模拟点击事件
        const a = document.createElement('a')
        // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
        const URL = window.URL || window.webkitURL
        // 根据解析后的blob对象创建URL 对象
        const herf = URL.createObjectURL(blob)
        // 下载链接
        a.href = herf
        // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
        a.download = filename
        document.body.appendChild(a)
        // 点击a标签，进行下载
        a.click()
        // 收尾工作，在内存中移除URL 对象
        document.body.removeChild(a)
        window.URL.revokeObjectURL(herf)
      })
    },

    getStationDataList() {
      let params = {
        region_id: 370200,
        // timetype:"day",
        // month:"日叠加:"+dayjs().subtract(1,'day').format("YYYY-MM-DD")+ " " +"2009:00:00:ALLPOINT",
        // time_range_type:"0"
      }
      return getStationDataList(params).then((res) => {
        this.satationDatas = res.data.data
        for (const satationData of this.satationDatas) {
          this.$set(satationData, 'switchFlg', false)
          let districtItem = districtOrder.find((x) => satationData.station.includes(x.title))
          if (districtItem) {
            satationData.sort = districtItem.order
          } else {
            satationData.sort = 99
          }
        }
        this.satationDatas = this.satationDatas.sort((a, b) => Number(a.sort) - Number(b.sort))
        this.showStations = this.satationDatas.filter((st) => st.station_level === '国')
        this.addStations(this.satationDatas.filter((st) => st.station_level === '国'))
        // this.visible = true;
      })
    },

    addStations(stations) {
      // 绘制国控站区域
      for (const station of stations) {
        const HbComp = Vue.extend(StationPage)
        var instance = new HbComp()
        instance.$mount()
        this.$set(instance, 'stationData', station)
        this.$refs.mapview?.drawStation(station, instance.$el)
      }
    },
    showDrawer() {
      this.visible = !this.visible
    },
    showMapSettings() {
      this.visibleMap = !this.visibleMap
    },
    satationChane(val) {
      this.$refs.mapview.removeStation(this.showStations)
      this.$refs.mapview.deleteStationCircle(this.showStations)
      let changeStations = []
      for (const iterator of val) {
        changeStations.push(...this.satationDatas.filter((st) => st.station_level === iterator))
      }
      this.addStations(changeStations)
      this.showStations = changeStations
      if (this.allSwitch) {
        this.drawStation(this.showStations)
      }
    },
    // 标站5公里范围绘制
    circleCheckedChange(val, props) {
      this.showStations.find((st) => st.sn === props.row.sn).switchFlg = val
      let stationDraw = this.showStations.filter((st) => st.sn === props.row.sn)
      if (val === true) {
        this.drawStation(stationDraw)
      } else if (val === false) {
        this.$refs.mapview.deleteStationCircle(stationDraw)
      }
    },
    // 设定标站绘制范围
    satationDrawRangeChange(val) {
      this.satationDrawRangeVal = val
      this.$refs.mapview.deleteStationCircle(this.showStations)
      this.drawStation(this.showStations.filter((st) => st.switchFlg === true))
    },
    circleAllCheckedChange(val) {
      this.$refs.mapview.deleteStationCircle(this.showStations)
      if (val === true) {
        this.drawStation(this.showStations)
      }
      for (const staDraw of this.showStations) {
        staDraw.switchFlg = val
      }
    },
    drawStation(stations) {
      this.$refs.mapview.drawStationCircle(stations, this.satationDrawRangeVal)
    },
    async setRegionTree() {
      try {
        const res = await getRegionTreeList()
        const { code, data } = res.data
        if (code === 200) {
          for (let i = 0; i < data.length; i++) {
            let changeData = data[i]

            if (changeData.name === '西海岸新区') {
              changeData.name = '黄岛区'
            }

            let districtItem = districtOrder.find((x) => changeData.name.includes(x.title))
            if (districtItem) {
              changeData.sort = districtItem.order
            } else {
              changeData.sort = 99
            }
          }
          let sotrData = data.sort((a, b) => Number(a.sort) - Number(b.sort))
          this.regionTree = sotrData.filter((x) => x.sort !== 99)
          console.log('🚀 ~ file: HomePage.vue:865 ~ setRegionTree ~ this.regionTree:', this.regionTree)
        }
      } catch (err) {
        console.log('🚀 ~ file: HomePage.vue:196 ~ getAreasData ~ err:', err)
      }
    },
    async selectedRegionChange(val) {
      this.drawRegion(val)
    },
    async getBdRegionBoundary(params) {
      const res = getBdRegionBoundary(params)
      return res
    },
    async getRegionBoundary(val) {
      const res = getRegionBoundary(val)
      return res
    },
    async getGdRegionBoundary(params) {
      const res = getGdRegionBoundary(params)
      return res
    },
    async drawRegion(val) {
      this.regionDisabled = true
      // 如果没有选择任何一个，删除图层，返回
      if (val.length == 0) {
        this.$refs.mapview.deleteRegionBoundary()
        return
      }

      // 请求队列
      let list = []
      let region = []
      for (let index = 0; index < val.length; index++) {
        const element = val[index]
        let name = element.split(':')[1]
        if (name === '高新区') {
          continue
        }
        console.log('🚀 ~ file: HomePage.vue:844 ~ selectedRegionChange ~ name:', name)

        if (this.bdRegion) {
          list.push(this.getBdRegionBoundary(name))
        }

        if (this.gdRegion) {
          list.push(this.getGdRegionBoundary(name))
        }
        region.push(element.split(':')[0])
      }

      if (this.nfRegion) {
        list.push(this.getRegionBoundary(region))
      }

      if (list && list.length > 0) {
        const res = await Promise.all(list)
        this.$refs.mapview.drawBdRegionBoundary(res)
        this.onRegionCheckChange()
      }else {
        this.$refs.mapview.deleteRegionBoundary()
        this.onRegionCheckChange()
        return
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.tableData = this.areasData.filter((x) => x.gid == selectedKeys[0])
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
      this.tableData = this.areasData
        .sort((a, b) => Number(b.area) - Number(a.area))
        .map((item, i) => ({
          ...item,
          area: Math.round(item.area),
          order: i + 1,
        }))
    },
    mapSettingChange(e) {
      if (e.target.value === 'gaodemap') {
        this.map = null
        this.mapVisible = 'gaode'
      } else if (this.mapVisible === 'wgs84') {
        this.$refs.mapview.mapChange(e.target.value, this.baseMapSwitch)
      } else {
        this.map = null
        this.mapVisible = 'wgs84'
        this.$nextTick(function () {
          this.initOriginalMap()
          this.$refs.mapview.mapChange(e.target.value, this.baseMapSwitch)
        })
      }
    },
    baseMapSwitchChange(val) {
      this.$refs.mapview.mapChange(this.mapSetting, val)
    },
    remoteSwitchChange(val) {
      this.$refs.mapview.changeRemoteLaVisible(val)
    },
    bareSoilSwitchChange(val) {
      if (val) {
        this.$refs.mapview.drawPolygons(this.areasData)
      } else {
        this.$refs.mapview.removePolygons()
      }
    },
    startDraw(val) {
      if (val) {
        this.$refs.mapview.drawPolygon()
      } else {
        this.$refs.mapview.exitDraw()
      }
    },
    closePopup() {
      console.log('closePopup')
      this.popupShowFlg = false
      this.popup.setPosition(undefined)
    },
    /**
     * 季度改变方法
     * @param val
     */
    handlequarterDataChange(val) {
      // 季度
      let quarter = Number(this.year + '0' + val)

      // 获取选择季度遥感拍摄地区
      this.remoteSensingDistrictList = this.remoteSensingImages
        .filter((x) => x.quarter === quarter)
        .sort((a, b) => Number(a.orderId) - Number(b.orderId))

      if (this.remoteSensingDistrict) {
        // 获取当前地区
        let district = this.remoteSensingImages.find((x) => x.value === this.remoteSensingDistrict[0]).district
        // 查找选择的季度中是否存在选择的地区
        if (!this.remoteSensingDistrictList.find((x) => x.district === district)) {
          // 不存在的话，选择设置为空
          this.remoteSensingDistrict = null
          this.clearMapAndBareSoil()
        } else {
          // 修改选择id
          this.remoteSensingDistrict = [this.remoteSensingDistrictList.find((x) => x.district === district).value,this.remoteSensingDistrict[1]]
          console.log("🚀 ~ handlequarterDataChange ~ this.remoteSensingDistrict:", this.remoteSensingDistrict)
          this.clearMapAndBareSoil()
          // 存在的话重新设置值
          this.reloadMapAndBareSoil()
          if (this.popupShowFlg) {
            // 重新读取popup的值
            // this.closePopup();
            this.$refs.popup.showPop(this.currentIdCopy, this.quarterData, this.year)
          }
        }
      }
    },
    /**
     * 清空画面上的所有数据
     */
    clearMapAndBareSoil() {
      // 清空画面所有数据
      // 遥感图像
      this.remoteLayerInfo = null
      this.$refs.mapview.clearRemote()
      // 裸土区域
      this.$refs.mapview.removePolygons()
      // 画面数据
      this.areasData = []
      // 右侧列表数据
      this.tableData = []
      // 圆形图数据
      this.chartData = []
      this.$refs.pieChart.updateChart(this.chartData)
      // // 关闭弹出画面
      // this.closePopup()
    },
    reloadMapAndBareSoil() {
      // 将最新区县提供给mapview
      this.remoteLayerInfo = this.remoteSensingImages.find((x) => x.value === this.remoteSensingDistrict[0])
      // 获取裸土区域列表数据
      this.getAreasDataList()
    },
    /**
     * 跳转管理界面
     */
    toManage() {
      this.$router.push({ path: '/manage' })
    },
    onRegionChange() {
      this.drawRegion(this.selectedRegion)
    },
    onRegionCheckChange(){
      this.regionDisabled = false
    },
    /**
     * 第三方数据开关变更
     */
    onThirdSwitchChange(val){
      // 根据开关状态，进行数据清洗
      if (val) {
        if(this.thirdSelectItems.length == 0){
         return getThirdSelectItems().then(res => {
          const {code, data} = res
          if (code === 200) {
            data.forEach(element => {
              this.thirdSelectItems.push(element)
            });
          }
        })
        }else if(this.thirdSelect){
          this.getThirdData(this.thirdSelect);
        }
      }else{
        this.$refs.mapview.removePoints();
        this.$refs.mapview.deleteMultiPolygon();
      }
    },
    onThirdSelectChange(val){
      this.thirdSelect = val
      if(this.thirdSwitch){
        this.getThirdData(val);
      }
    },
    getThirdData(val) {
      this.$refs.mapview.removePoints();
      this.$refs.mapview.deleteMultiPolygon()
      if (val?.length) {
        const urls = val.map(t => {
          return getThirdData({
            "title": t,
            "district": this.remoteSensingDistrictList.find((x) => x.value === this.remoteSensingDistrict[0]).label
          })
        })
        return Promise.all(urls).then(resp => {
          console.log(resp);
          if (resp?.length) {
            let pointData = []
            let multiPolygonData = []
            resp.forEach((res, index) => {
              const {code, data} = res
              if (code === 200) {
                let pointDataFilter = data.filter(x => x.type == 'Point')
                if (pointDataFilter?.length) {
                  pointDataFilter.forEach(item => {
                    item.thirdTitle = val[index]
                  })
                  pointData = [...pointData, ...pointDataFilter]
                }
                let multiPolygonDataFilter = data.filter(x => x.type == 'MultiPolygon')
                if (multiPolygonDataFilter?.length) {
                  multiPolygonDataFilter.forEach(item => {
                    item.thirdTitle = val[index]
                  })
                  multiPolygonData = [...multiPolygonData, ...multiPolygonDataFilter]
                }
              }
            })
            this.$refs.mapview.drawPoints(pointData)
            this.$refs.mapview.drawMultiPolygon(multiPolygonData)
          }
        })
        .catch(error => {
          console.error(error);
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
@import url('../assets/style.less');
.map-container-enter-active {
  animation: aaa 0.5s linear;
}

.map-container-leave-active {
  animation: aaa 0.5s linear reverse;
}

@keyframes aaa {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
}
/deep/ .ant-table-thead > tr > th {
  background: #324057 !important;
  color: white;
  font-weight: bold;
  text-align: center;
  border: 1px solid #435169;
  border-right: 1px solid #435169 !important;
}
/deep/ .ant-table-body > table {
  border: none !important;
}
/deep/ .ant-table-thead > tr > td {
  border: 1px solid #324057;
}
/deep/ .ant-table table {
  border: none;
}

/deep/ .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: rgba(10, 139, 198, 0.5);
}

/deep/ .ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #fff !important;
}

/deep/ .ant-pagination-item a {
  color: #fff !important;
}
/deep/ .ant-table-pagination li {
  background-color: #324057 !important;
}

/deep/ .ant-table-pagination a {
  color: #fff !important;
}

/deep/ .ant-pagination-item-ellipsis {
  color: #fff !important;
}

/deep/ .ant-checkbox + span {
  color: #fff !important;
}
.region {
  margin-top: 5px;
}
.centered-container {
  margin-top: 10px
}
.thirdselectcss{
    width: 200px;
}
/deep/ .rectification-switch-class::after {
  top: 6px;
}
</style>
